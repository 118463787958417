<script>
    import { getContext, onMount } from "svelte";
    import { Container, Row, Col, Button, Input } from "sveltestrap";
    import Layout from "./Layout.svelte";
    import { init, __ } from "../i18n";
    const { catalog } = getContext("global.stores");
    init($catalog);

    export let title;
    export let locale;

    let language;
    
    const changeLanguage = (e) => {
        const lang = e.target.value // use this because 'language' value is returning prev value 
        window.location = "/switch?lang=" + lang;
    };

    onMount(() => {
        if(locale) language = locale;
    });

</script>

<svelte:head>
    <title>{title}</title>
</svelte:head>

<Layout>
    <Container class="language offset-md-8 col-md-2 offset-sm-8 col-sm-3 m-md-x-auto col-lg-2 offset-lg-9 col-xl-1 offset-xl-10">
        <Row>
            <Col >
                <Input type="select" bind:value={language} on:change={changeLanguage}>
                    <option value="en">English</option>
                    <option value="my">Malay</option>
                    <option value="lo">Lao</option>
                </Input>
            </Col>
        </Row>
    </Container>
    <Container class="p-4 col-md-8 col-sm-10 m-md-x-auto col-lg-3 offset-lg-8">
        <Row>
            <img src="images/logo.png" alt="logo" />
        </Row>
        <Row class="login">
            <Col md="12">
                <Row>
                    <p class="mb-0 text-break">{__("LOGIN_TITLE")}</p>
                </Row>
                <Row>
                    <Col>
                        <a href="/login?app"><Button color="custom" size="sm" class="mt-4 mb-2">{__("LOGIN_APP")}</Button></a>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <a href="/login?otp"><Button color="custom" size="sm" class="mt-3 mb-2">{__("LOGIN_OTP")}</Button></a>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
</Layout>

<style>
    :global(button) {
        width: 100%;
        color: var(--login-button-text-color) !important;
        background-color: var(--login-button-bg-color) !important;
        border-color: var(--login-button-bg-color) !important;
    }
    :global(button:hover) {
        background-color: var(--login-button-hover-bg-color) !important;
        border-color: var(--login-button-hover-bg-color) !important;
    }
    :global(button:active) {
        border-style: inset;
    }
    :global(.container) {
        margin-top: calc(50vh - 220px);
    }
    :global(.container.language) {
        margin-top: 30px;
    }
    :global(.language select) {
        min-width: 105px;
    }
    :global(.login) {
        margin-top: 20px;
        border-radius: 5px;
        padding: 15px 10px;
        background-color: rgba(92, 155, 239, 0.1);
        color: var(--login-text-color);
        box-shadow: 2px 2px 10px rgba(255, 255, 255,0.2);
    }
    @media only screen and (max-width: 992px) {
        :global(.login) {
            background-color: transparent;
            box-shadow: none;
        }
        :global(.container) {
            background-color: var(--login-container-bg-color);
            border-radius: 5px;
        }
    }
</style>